import React from 'react';

import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {toTimeString} from "../../../utils/date";
import {priceSet} from "../../../utils/price";

import './OrderView.scss';

const OrderView = ({selectBayer}) => {
  return (
    <div className={'order-view'}>
      <div className="order-view__header">
        <div className="order-view__header-title">{selectBayer?.data?.order_number}</div>
        <div className="order-view__header-status">Оплачен</div>
        <button className="btn order-view__btn">Редактировать</button>
      </div>

      <div className="order-view__status">
        {selectBayer?.statusOrder?.name && <div className="order-view__tag order-view__tag--new">{selectBayer?.statusOrder?.name}</div>}
        {/*<div className="order-view__tag order-view__tag--complited">Выполнен</div>*/}
        <div className="order-view__last-time">Последнее изменение: {toTimeString(selectBayer?.data?.created_utc)}</div>
      </div>

      <div className="order-view__body">
        <div className="order-view__buyer buyer">
          <div className="buyer__info">
            <div className="buyer__title">Покупатель</div>
            <div className="buyer__name">{selectBayer?.buyer?.name}</div>
            <div className="buyer__contacts">
              {selectBayer?.buyer?.phone && <div className="buyer__phone">Телефон: {selectBayer?.buyer?.phone}</div>}
              {selectBayer?.buyer?.email && <div className="buyer__email">E-mail: {selectBayer?.buyer?.email}</div>}
            </div>
            {selectBayer?.buyer?.inn && (
              <>
                <div className="buyer__title">ИНН</div>
                <div className="buyer__inn">{selectBayer?.buyer?.inn}</div>
              </>
            )}

            {selectBayer?.buyer?.comment && (
              <>
                <div className="buyer__title">Коментарий</div>
                <div className="buyer__commnents">{selectBayer?.data?.comment}</div>
              </>
            )}

            {selectBayer?.data?.delivery?.name && (
              <>
                <div className="buyer__title">Доставка</div>
                <div className="buyer__delivery">{selectBayer?.data?.delivery?.name}</div>
              </>
            )}

            {selectBayer?.data?.payment?.name && (
              <>
                <div className="buyer__title">Оплата:
                  <div className="buyer__pay">{selectBayer?.data?.payment?.name}</div>
                </div>
              </>
            )}
          </div>
        </div>

        {selectBayer?.data?.file_infos.length ? (
          <div className={'buyer__documents'}>
            <div className={'buyer__title'}>Документы</div>
            {
              selectBayer?.data?.file_infos.map(item => (
                <div key={item.id} className={'buyer__documents-row'}>
                  <div>{item.name}</div>
                  <a
                    className={'order-view__download'}
                    href={`https://gateway.okibiteam.ru/sale-order-service/file/${item.id}`}><ArrowDownload20Regular /></a>
                </div>))
            }
          </div>): ''
        }

        {selectBayer?.data?.order_items.length !== 0 ? (
          <div className="order-view__specification">
            <div className="specification">
              <div className="specification-row">
                <div className="specification-column">Наименование товара</div>
                <div className="specification-column">Количество</div>
                <div className="specification-column">Цена</div>
                <div className="specification-column">Сумма</div>
              </div>
              {selectBayer?.data?.order_items.map((item, i) => (
                <div className="specification-row" key={i}>
                  <div className="specification-column">{item?.name}</div>
                  <div className="specification-column">{item?.count}</div>
                  <div className="specification-column">{priceSet((item?.product_price.price)/100)} ₽</div>
                  <div className="specification-column">{priceSet((item?.product_price.price * item?.count / 100))} ₽</div>
                </div>
              ))}
            </div>
          </div>) : 'В заказе отсутствуют товары'}

        <div className="order-view__total-wrapper">
          <div className="order-view__total">
            <div className="">Подытог: <span>{priceSet(((selectBayer?.data?.order_items
              .reduce((sum, current) =>  sum + (current.product_price.price * current.count), 0))) / 100
              )} ₽</span></div>
            <div className="">Доставка: <span>{priceSet((selectBayer?.data?.delivery.delivery_price.price)/100)} ₽</span></div>
            <div className="">Итого: <span>{priceSet((selectBayer?.data?.order_sum.price) / 100)} ₽</span></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderView;