import React from 'react';
import Header from "../Header/Header";
import {Outlet} from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import './layout.scss'

const Layout = () => {
  return (
    <div className={'layout'}>
      <Header />
      <Navigation />
      <div className={'layout__container'}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;