// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders {
  display: grid;
  grid-template-columns: 425px 1fr;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 15px;
  padding: 0 5px 0 0;
  height: 100%;
}
.orders__header {
  padding: 16px;
  display: flex;
}
.orders__header-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/pages/Orders/orders.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gCAAA;EAEA,oCAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAEE;EACE,aAAA;EACA,aAAA;AAAJ;AAEI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAAN","sourcesContent":[".orders {\n  display: grid;\n  grid-template-columns: 425px 1fr;\n\n  border: 1px solid rgba(232, 232, 232, 1);\n  border-radius: 15px;\n  padding: 0 5px 0 0;\n  height: 100%;\n\n  &__header {\n    padding: 16px;\n    display: flex;\n\n    &-title {\n      font-weight: 600;\n      font-size: 24px;\n      line-height: 34px;\n      text-transform: uppercase;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
