import React from 'react';
import {Link, useLocation} from "react-router-dom";
import './NavigationContainer.scss';
const NavigationContainer = (props) => {
  const location = useLocation();

  const {itemContainer} = props;
  return (
    <div className={'navigation-container'}>
      <h3 className={'navigation-container__title'}>{itemContainer.title}</h3>
      <ul className={'navigation-container__list'}>
        {itemContainer.list.map((item, i) => {
          return (
          <li
            key={i}
            className={'navigation-container__list-item'}
          >
            <Link to={item.href} className={`navigation-container__link${location.pathname === item.href ? ' navigation-container__link--active' : ''}`}>
              <span className="navigation-container__icon">{item.icon}</span>
              {item.title}
            </Link>
          </li>
          )
        })}
      </ul>
    </div>
  );
};

export default NavigationContainer;