import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const productsRequest = () => axios.get(`https://gateway.okibiteam.ru/products`)
  .then((resp) => resp.data );

export const fetchProductItems = createAsyncThunk(
  'data/fetchProductsStatus', async () => {

    const [products] = await Promise.all([productsRequest()]);
    return {
      products,
    }
  });

const initialState = {
  productsData: [],
  cloneProductsData: [],
  status: 'loading'
};

const productsSlice = createSlice({
  name: 'products',
  initialState,

  reducers: {
    setData(state, action)  {
      state.cloneProductsData.results = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchProductItems.pending, (state) => {
      state.status = 'loading';
      state.productsData = [];
      state.cloneProductsData = [];
    });

    builder.addCase(fetchProductItems.fulfilled, (state, action) => {
      state.status = 'success';
      state.productsData = action.payload;
      state.cloneProductsData = action.payload;
    });

    builder.addCase(fetchProductItems.rejected, (state) => {
      state.status = 'error';
      state.productsData = [];
      state.cloneProductsData = [];
    });
  }
});

export const selectorData = state => state.productsReducer;
export const { setData } = productsSlice.actions;
export default productsSlice.reducer;
