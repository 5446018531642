import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const orderRequest = () =>  axios.get(`https://gateway.okibiteam.ru/sale-order-service/order`)
  .then((resp) => resp.data );
const buyerRequest = () => axios.get(`https://gateway.okibiteam.ru/sale-order-service/buyer`)
  .then((resp) => resp.data );
const statusOrderRequest = () => axios.get(`https://gateway.okibiteam.ru/sale-order-service/order-status`)
  .then((resp) => resp.data );

export const fetchDataItems = createAsyncThunk(
  'data/fetchDataStatus', async () => {

    const [order, buyer, statusOrder] = await Promise.all([orderRequest(), buyerRequest(), statusOrderRequest()]);
    return {
      order,
      buyer,
      statusOrder
    }
  });

const initialState = {
  itemsData: [],
  cloneItemsData: [],
  status: 'loading'
};

const dataSlice = createSlice({
  name: 'data',
  initialState,

  reducers: {
    setData(state, action)  {
      state.cloneItemsData.results = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDataItems.pending, (state) => {
      state.status = 'loading';
      state.itemsData = [];
      state.cloneItemsData = [];
    });

    builder.addCase(fetchDataItems.fulfilled, (state, action) => {
      state.status = 'success';
      state.itemsData = action.payload;
      state.cloneItemsData = action.payload;
    });

    builder.addCase(fetchDataItems.rejected, (state) => {
      state.status = 'error';
      state.itemsData = [];
      state.cloneItemsData = [];
    });
  }
});

export const selectorData = state => state.dataReducer;
export const { setData } = dataSlice.actions;
export default dataSlice.reducer;