import React, {useState} from 'react';

import {priceSet} from "../../../utils/price";
import {toTimeString} from "../../../utils/date";
import Loading from "../../Loading/loading";

import './OrdersList.scss';
import {getStatusOrder} from "../../../utils/order";

const OrdersList = (props) => {
  const [activeItem, setActiveItem] = useState(0);
  const {onSelectBayer, itemsOrder, itemsStatus, itemsBuyer, addOrder} = props;

  const getName = (value) => itemsBuyer?.find(item => item.uuid === value)?.name;
  const getBuyer = (value) =>  {
     return fetch(`https://gateway.okibiteam.ru/sale-order-service/order/${value.uuid}/extended`)
       .then((response) => response.json())
       .then((data) =>onSelectBayer({
         data: data,
         buyer: itemsBuyer?.find(item => item.uuid === value.buyer.buyer_uuid),
         statusOrder: getStatusOrder(value, itemsStatus)
       }))
  }

  return (
    <div className={'orders-list'}>
      <div className={"orders-list__header"}>
        <div className={"orders-list__title"}>Все заказы</div>
        <button
          className={"orders-list__add"}
          disabled={itemsOrder ? false : true}
          onClick={() => {
            addOrder(true)
          }}
        >+</button>
      </div>
      <div className="orders-list__wrapper">
        <ul className={"orders-list__items"}>
          {itemsOrder ? ([...itemsOrder].reverse().map((item, i) => {
          return(
            <li
              key={item.uuid}
              className={`orders-list__item ${activeItem === i ? 'active' : ''}`}
              onClick={() => {
                setActiveItem(i);
                getBuyer(item)
              }}
            >
            <div className="orders-list__name">{item?.order_number}</div>
            <div className="orders-list__person">{getName(item.buyer.buyer_uuid)}</div>
            <div className="orders-list__status">Новый</div>
            <div className="orders-list__price">{priceSet((item.order_sum.price) / 100)} ₽</div>
            <div className="orders-list__data">{toTimeString(item?.created_utc)}</div>
          </li>)

          })) : <Loading message={'Подождите, идет загрузка заказов'} />}
        </ul>
      </div>
    </div>
  );
};

export default OrdersList;