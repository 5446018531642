import React from 'react';
import {Route, Routes} from "react-router-dom";
import Layout from "../Layout/Layout";
import OrdersPage from "../../pages/Orders/OrdersPage";

const RouteComponent = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Layout />}>
        <Route index  element={<OrdersPage />}/>
        <Route path={'/orders'} element={<OrdersPage />}/>
      </Route>
    </Routes>
  );
};

export default RouteComponent;