// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: grid;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100vh;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 50px 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.layout__container {
  grid-area: 2/2/2/2;
  padding: 15px 10px 15px 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oCAAA;EACA,WAAA;EACA,aAAA;EAEA,gCAAA;EACA,4BAAA;EACA,kBAAA;EACA,eAAA;AAAF;AAEE;EACE,kBAAA;EACA,4BAAA;AAAJ","sourcesContent":[".layout {\n  display: grid;\n  background-color: rgba(255, 255, 255, 1);\n  width: 100%;\n  height: 100vh;\n\n  grid-template-columns: 300px 1fr;\n  grid-template-rows: 50px 1fr;\n  grid-column-gap: 0;\n  grid-row-gap: 0;\n\n  &__container {\n    grid-area: 2 / 2 / 2 / 2;\n    padding: 15px 10px 15px 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
