import {
  DocumentFolder24Regular,
  DocumentTableCheckmark24Regular
} from '@fluentui/react-icons';

export const NavigationList = [
  {
    title: 'Договора',
    list: [
      {
        title: 'Подготовка договора',
        icon: <DocumentFolder24Regular />,
        href: '/'
      },
      {
        title: 'Список маршрутов',
        icon: <DocumentFolder24Regular />,
        href: '/'
      },
    ]
  },
  {
    title: 'Задачи',
    list: [
      {
        title: 'Список задач',
        icon: <DocumentFolder24Regular />,
        href: '/'
      }
    ]
  },
  {
    title: 'Интернет-магазин',
    list: [
      // {
      //   title: 'Товары',
      //   icon: <ShoppingBag24Regular />,
      //   href: '/products'
      // },
      {
        title: 'Заказы',
        icon: <DocumentTableCheckmark24Regular />,
        href: '/orders'
      },
    ]
  },
]
