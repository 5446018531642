import { configureStore } from '@reduxjs/toolkit'

import dataSlice from "./dataSlice";
import orderSlice from "./orderSlice";
import productsSlice from "./productSlice";

const store = configureStore({
  reducer: {
    dataReducer: dataSlice,
    orderReducer: orderSlice,
    productsReducer: productsSlice
  },
});
export default store;