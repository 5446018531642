import React from 'react';
import { ArrowLeft24Filled } from '@fluentui/react-icons';

import './headers.scss';

const Header = () => {
  return (
    <header className={'header'}>
      <ArrowLeft24Filled />
    </header>
    );
};

export default Header;