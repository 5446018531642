import React, {useEffect} from 'react';

import './App.css';
import RouteComponent from "./components/RouteComponent/RouteComponent";
import {FluentProvider, teamsLightTheme} from "@fluentui/react-components";
import {fetchDataItems} from "./Redux/dataSlice";
import {useDispatch} from "react-redux";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = () => dispatch(fetchDataItems());
    getData();
  }, [])

  return (
    <div className="App">
      <FluentProvider theme={teamsLightTheme}>
        <RouteComponent />
      </FluentProvider>
    </div>
  );
}

export default App;
