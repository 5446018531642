import React, {useEffect, useRef, useState} from 'react';

import {ArrowLeft24Filled, Delete24Regular} from "@fluentui/react-icons";
import {Combobox, Input, Label, Radio, RadioGroup, Option, Textarea} from "@fluentui/react-components";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
  addBuyer,
  addDeliveryMethodUUID,
  addDeliveryName,
  addDeliveryPrice, addItem, addPayment, decItem, incItem, removeItem,
  selectorOrder
} from "../../../Redux/orderSlice";

import {priceSet} from "../../../utils/price";

import './OrdersAdd.scss'
import {fetchProductItems} from "../../../Redux/productSlice";

const OrdersAdd = (props) => {
  const {closeAdd, itemsBuyer} = props;
  const [deliveryItems, setDeliveryItems] = useState(null)
  const [paymentItems, setPaymentItems] = useState(null);

  const commentRef = useRef(null);
  const deliveryPriceRef = useRef(null);
  const deliveryMethodRef = useRef(null);

  const { productsData } = useSelector(state => state.productsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = () => dispatch(fetchProductItems());
    getData();
  }, [])

  const {buyer, delivery, payment, order_items} = useSelector(selectorOrder);

  const handleSubmit = async (e) => {
    e.preventDefault()

    const orderData = {
      "order_items": order_items,
      "buyer": buyer,
      "delivery": {
        "delivery_method_uuid": delivery.delivery_method_uuid,
        "delivery_price": {
          "price": delivery.delivery_price.price,
          "currency": "kop"
        },
        "name": deliveryMethodRef.current.value
      },
      "payment": {
        "payment_method_uuid": payment?.payment_method_uuid,
        "name": payment?.name
      },
      "comment": commentRef.current.value
    }

    try {
      let res = await axios.post('https://gateway.okibiteam.ru/sale-order-service/order', orderData)
    } catch (err) {
      console.log(err);
    }

    closeAdd(false)
  }

  return (
    <form onSubmit={handleSubmit} className={'orders-add'}>
      <div className="orders-add__buyer">
        <div className="orders-add__header">
          <ArrowLeft24Filled onClick={() => {
            closeAdd(false)
          }}/>
          <div className={"orders-add__title"}>Покупатель</div>
          <button
            className={"orders-add__add"}
            onClick={() => {}}
          >+
          </button>
        </div>

        <div className="orders-add__body">
          <div className={'orders-add__row'}>
            <Combobox>
              {itemsBuyer?.map((option) => (
                <Option
                  key={option.uuid}
                  onClick={()=> {
                    dispatch(addBuyer(option))
                  }}
                >
                  {option.name}
                </Option>
              ))}
            </Combobox>
          </div>

          <div className={'orders-add__buyer-radio-button'}>
            <RadioGroup {...props} defaultValue={'person'}>
              <Radio label="Физическое лицо" value="person"/>
              <Radio label="Юридическое лицо" value="company"/>
            </RadioGroup>
          </div>

          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor='name' >
              Имя
            </Label>
            <Input disabled id='name' />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'patronymic'} >
              Отчество
            </Label>
            <Input disabled id={'patronymic'} />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'surname'} >
              Фамилия
            </Label>
            <Input disabled id={'surname'} />
          </div>

          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'phone'} >
              Телефон
            </Label>
            <Input type={"tel"} id={'phone'} />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'email'}>
              E-mail
            </Label>
            <Input type={'email'} id={'email'} />
          </div>

          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'addInfo'} >
              Дополнительная информация
            </Label>

            <Textarea id={'addInfo'} />
          </div>


          <div className={"orders-add__title"}>
            Адрес доставки товара
          </div>

          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'address'} >
              Адрес
            </Label>
            <Textarea id={'address'} />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'city'} >
              Город:
            </Label>
            <Input disabled id={'city'} />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'region'} >
              Регион:
            </Label>
            <Input disabled id={'region'} />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'index'} >
              Индекс:
            </Label>
            <Input disabled id={'index'} />
          </div>
          <div className={'orders-add__row'}>
            <Label className={'orders-add__label'} htmlFor={'country'} >
              Страна:
            </Label>
            <Input disabled id={'country'} />
          </div>
        </div>
      </div>

      <div className="orders-add__description">
        <div className={'orders-add__header-description'}>
          <div className={"orders-add__title"}>Добавьте товар</div>
          <button className={"orders-add__save"} onClick={handleSubmit}>Сохранить заказ</button>
        </div>

        <div className={'orders-add__row'}>
          <Label className={'orders-add__label'} htmlFor={'address'}>
            Комментарий к заказу:
          </Label>

          <Textarea
            id={'address'}
            ref={commentRef}
          />

          {order_items.length !== 0 ? (
              <div className={'orders-add__specification'}>
                {order_items?.map((item) => (
                  <div className={'orders-add__specification-row'} key={item.uuid}>
                    <div className={'orders-add__specification-name'}>{item?.name}</div>
                    <div className={'orders-add__specification-price'}>{priceSet((item?.product_price.price) / 100)} / шт</div>
                    <div className={'orders-add__specification-btn'} onClick={() => {
                      dispatch(decItem(item))
                    }}>-
                    </div>
                    <div className={'orders-add__specification-count'}>{item?.count}</div>
                    <div className={'orders-add__specification-btn'} onClick={() => {
                      dispatch(incItem(item))
                    }}>+
                    </div>

                    <div
                      className={'orders-add__specification-sum'}>{priceSet((Number(item?.product_price.price) * Number(item?.count)) / 100)}</div>
                    <Delete24Regular onClick={() => {
                      dispatch(removeItem(item))
                    }}/>
                  </div>))}

                <div className={'orders-add__total'}>Подытог: {priceSet(((order_items
                  .reduce((sum, current) => sum + (current.product_price.price * current.count), 0)) / 100))} ₽
                </div>
              </div>
            )
            : ''}
        </div>

        <div className={'orders-add__row'}>
          <Label className={'orders-add__label'} htmlFor={'selectProduct'} >
            Выберите товар из списка:
          </Label>
          <Combobox
            id={'selectProduct'}
            placeholder="Выберите товар из списка:"
          >
            {productsData?.products?.product_list ? productsData?.products?.product_list.map((option) => (
              <Option
                text={option.name}
                key={option.uuid}
                onClick={()=> {
                  dispatch(addItem(option))
                }}
                value={option.uuid}
              >
                {option.name}
              </Option>
            )) : ''}
          </Combobox>
        </div>

        <div className={'orders-add__row'}>
          <Label
            className={'orders-add__label'}
            htmlFor={'delivery'}
            size={props.size}
            disabled={props.disabled}
          >
            Доставка:
          </Label>
          <Combobox
            onClick={() => {
              return fetch('https://gateway.okibiteam.ru/sale-order-service/delivery')
                .then((response) => response.json())
                .then((data) => setDeliveryItems(data));
            }}
            id={'delivery'}
            placeholder="Выберите способ доставки"
            ref={deliveryMethodRef}
          >
            {deliveryItems ? deliveryItems.map((option) => (
              <Option
                text={option.name}
                key={option.uuid}
                onClick={()=> {
                  dispatch(addDeliveryMethodUUID(option.uuid))
                  dispatch(addDeliveryName(option.name))
                }}
                value={option.uuid}
              >
                {option.name}
              </Option>
            )) : ''}
          </Combobox>
        </div>

        <div className={'orders-add__row'}>
          <Label className={'orders-add__label'} htmlFor={'deliveryTotal'}>
            Стоимость доставки:
          </Label>
          <Input
            placeholder={0}
            onChange={(evt) => {
            dispatch(addDeliveryPrice(evt.target.value))
          }} ref={deliveryPriceRef} id={'deliveryTotal'} />
        </div>

        <div className={'orders-add__row'}>
          <Label className={'orders-add__label'} htmlFor={'deliveryPaymentChecked'} size={props.size} disabled={props.disabled}>
            Оплата:
          </Label>

          <Combobox
            id={'deliveryPaymentChecked'}
            placeholder="Выберите способ оплаты"
            onClick={() => {
              return fetch('https://gateway.okibiteam.ru/sale-order-service/payment')
                .then((response) => response.json())
                .then((data) => setPaymentItems(data));
            }}
          >
            {paymentItems ? paymentItems.map((option) => (
              <Option
                key={option.uuid}
                onClick={()=> {
                  dispatch(addPayment(option))
                }}
                value={option.uuid}
              >
                {option.name}
              </Option>
            )) : ''}
          </Combobox>
        </div>

        <div className={'orders__total'}>
          Итого: {priceSet(((order_items
          .reduce((sum, current) =>  sum + (current.product_price.price * current.count), 0)) + delivery.delivery_price.price) / 100)} ₽
        </div>
      </div>
    </form>
  );
};

export default OrdersAdd;