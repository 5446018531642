// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-container__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-bottom: 10px;
}
.navigation-container__list {
  list-style: none;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}
.navigation-container__list-item + .navigation-container__list-item {
  margin-top: 8px;
}
.navigation-container li, .navigation-container a {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.navigation-container__link {
  position: relative;
  padding-left: 8px;
}
.navigation-container__link.navigation-container__link--active {
  color: rgb(0, 120, 212);
}
.navigation-container__link.navigation-container__link--active:before {
  content: "";
  display: flex;
  width: 4px;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: rgb(0, 120, 212);
}
.navigation-container__icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/NavigationContainer/NavigationContainer.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAIM;EACE,eAAA;AAFR;AAOE;EAEE,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AANJ;AASE;EACE,kBAAA;EACA,iBAAA;AAPJ;AASI;EACE,uBAAA;AAPN;AASM;EACE,WAAA;EACA,aAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,2BAAA;EACA,kCAAA;AAPR;AAYE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAVJ","sourcesContent":[".navigation-container {\n  &__title {\n    font-size: 24px;\n    font-weight: 600;\n    line-height: 32px;\n    text-align: left;\n    margin-bottom: 10px;\n  }\n\n  &__list {\n    list-style: none;\n    margin-bottom: 24px;\n    display: flex;\n    flex-direction: column;\n\n    &-item {\n      & + & {\n        margin-top: 8px;\n      }\n    }\n  }\n\n  & li,\n  & a {\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n  }\n\n  &__link {\n    position: relative;\n    padding-left: 8px;\n\n    &.navigation-container__link--active {\n      color: rgba(0, 120, 212, 1);\n\n      &:before {\n        content: '';\n        display: flex;\n        width: 4px;\n        height: 100%;\n        border-radius: 2px;\n        position: absolute;\n        top: 50%;\n        left: 0;\n        transform: translateY(-50%);\n        background-color: rgba(0, 120, 212, 1);\n      }\n    }\n  }\n\n  &__icon {\n    display: block;\n    width: 24px;\n    height: 24px;\n    margin-right: 6px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
