import React from 'react';
import './Navigation.scss';
import {Link} from "react-router-dom";
import logo from '../../assets/img/okibi-logo-2.png';
import {NavigationList} from "./NavigationList";
import NavigationContainer from "./NavigationContainer/NavigationContainer";
const Navigation = () => {
  return (
    <div className={'navigation'}>
      <Link className={'logo'} to={'/'}>
        <img src={logo} alt="" width={'200px'}/>
      </Link>

      {NavigationList.map((item, i) => <NavigationContainer itemContainer={item} key={i} />)
      }
    </div>
  );
};

export default Navigation;