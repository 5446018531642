import React from 'react';
import {Spinner} from "@fluentui/react-components";

import './loading.scss';

const Loading = (props) => {
  const {message} = props

  return (
    <div>
      <div className={'loading'}>{message} <Spinner/></div>
    </div>
  );
};

export default Loading;