// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
  grid-area: 2/1/6/2;
  padding: 10px 15px 10px 25px;
}

.logo {
  margin-bottom: 48px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,4BAAA;AACF;;AACA;EACE,mBAAA;EACA,aAAA;AAEF","sourcesContent":[".navigation {\n  grid-area: 2 / 1 / 6 / 2;\n  padding: 10px 15px 10px 25px;\n}\n.logo {\n  margin-bottom: 48px;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
