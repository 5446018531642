import React, {useEffect, useState} from 'react';

import {useSelector} from "react-redux";

import OrdersList from "../../components/Orders/orders-list/OrdersList";
import OrderView from "../../components/Orders/OrderView/OrderView";
import OrdersAdd from "../../components/Orders/OrdersAdd/OrdersAdd";

import './orders.scss';
import {getStatusOrder} from "../../utils/order";

const OrdersPage = () => {
  const { status, itemsData } = useSelector(state => state.dataReducer);
  const [selectBayer, setSelectBayer] = useState(null);
  const [addOrder, setAddOrder] = useState(null);


  useEffect(() => {
    if (status === 'success') {
      const getBuyer = async () =>  {
        return await fetch(`https://gateway.okibiteam.ru/sale-order-service/order/${[...itemsData?.order].reverse()[0]?.uuid}/extended`)
          .then((response) => response.json())
          .then((data) => setSelectBayer({
            data: data,
            buyer: itemsData?.buyer.find(item => item.uuid === [...itemsData?.order].reverse()[0].buyer.buyer_uuid),
            statusOrder: getStatusOrder([...itemsData?.order].reverse()[0], itemsData?.statusOrder)
          }))
      }
      getBuyer()
    }
  }, [status]);

  return (
    <div className={'orders'}>
      {addOrder ? (<OrdersAdd
        closeAdd={setAddOrder}
        itemsBuyer={itemsData?.buyer}
      />) : (<>
        <OrdersList
          onSelectBayer={setSelectBayer}
          itemsOrder={itemsData?.order}
          itemsBuyer={itemsData?.buyer}
          itemsStatus={itemsData?.statusOrder}
          addOrder={setAddOrder}
        />
        {status === 'success' ? (<OrderView
          selectBayer={selectBayer}
        />) : ''}
      </>)}
    </div>
      );
};

export default OrdersPage;