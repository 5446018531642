import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  order_items: [],
  buyer: '',
  delivery: {
    delivery_method_uuid: '',
    delivery_price: {
      price: 0,
      currency: 'kop'
    },
    name: ''
  },
  payment: {
    payment_method_uuid: '',
    name: ''
  },
  comment: ''
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addBuyer(state, action) {
      state.buyer = action.payload
    },
    addDeliveryPrice(state, action) {
      state.delivery.delivery_price.price = Number(action.payload)
    },
    addDeliveryMethodUUID(state, action) {
      state.delivery.delivery_method_uuid = action.payload
    },
    addDeliveryName(state, action) {
      state.delivery.name = action.payload
    },
    addPayment(state, action) {
      state.payment.payment_method_uuid = action.payload.uuid
      state.payment.name = action.payload.name
    },
    addItem(state, action) {
      state.order_items.push({
        product_uuid: action.payload.uuid,
        name: action.payload.name,
        product_price: {
          price: Number(action.payload.price.data),
          currency: 'kop'
        },
        count: action.payload.count,
        unit: '',
        order_item_sum: {
          price: Number(action.payload.price.data * action.payload.count),
          currency: 'kop'
        }
      });
    },
    removeItem(state, action) {
      state.order_items = state.order_items.filter(item => item.product_uuid !== action.payload.product_uuid);
    },
    decItem(state, action) {
      const findItem = state.order_items.find((item) => item.product_uuid === action.payload.product_uuid);

      if (findItem && findItem.count > 0) {
        findItem.count--;
      }
    },
    incItem(state, action) {
      const findItem = state.order_items.find((item) => item.product_uuid === action.payload.product_uuid);

      if (findItem) {
        findItem.count++;
      }
    }
  }
});

export const selectorOrder = state => state.orderReducer;

export const {addBuyer, incItem, decItem, removeItem, addPayment, addDeliveryPrice, addDeliveryName, addDeliveryMethodUUID, addItem} = orderSlice.actions;

export default  orderSlice.reducer